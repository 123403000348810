.applyCoupon{
    border: none;
    box-shadow: none;
    background: transparent;
}
.applyCoupon:hover, .applyCoupon:disabled, .applyCoupon:disabled:hover{
    background: transparent;
}

// addonAfter
@primary-color: undefined;@success-color: undefined;@disabled-color: undefined;@secondary-color: undefined;