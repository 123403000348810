@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkW-EL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTg.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTg.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwARZthTg.ttf) format('truetype');
}
:root {
  --dk-grey-color: #717171;
  --disabled-color: undefined;
  --blkcol: #1e1e1e;
  --bordcol: #d4d4d4;
  --pri-white: #ffffff;
  --lt-grey-color: #cccccc;
  --bgbtnblu: #035d9f;
  --primary-color: undefined;
  --belgry: #F0F0F0;
}
body {
  box-sizing: border-box;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
.none {
  display: none;
}
.h1s {
  font-size: 25px !important;
  font-weight: 600;
  margin: 0;
}
.fmp {
  font-size: 19px;
  font-weight: bolder;
}
.ft16 {
  font-size: 16px;
}
.tcent,
.tcents {
  text-align: center;
}
p {
  font-size: 16px;
  line-height: 30px;
}
.pt4 {
  padding-top: 4rem;
}
.py1 {
  padding: 2rem 0;
}
.py0 {
  padding: 1rem 0;
}
.mt1 {
  margin-top: 2rem;
}
.mt2 {
  margin-top: 3rem;
}
.mr {
  margin-right: 1rem;
}
.mr1 {
  margin-right: 2rem;
}
.mx1 {
  margin: 0 2rem;
}
.pb1 {
  padding-bottom: 3rem;
}
.pl0 {
  padding-left: 1rem ;
}
.pb0 {
  padding-bottom: 1.5rem;
}
.px {
  padding: 0 3rem;
}
.px0 {
  padding: 0 1.5rem;
}
.circleNotif {
  border-radius: 50%;
  background-color: #EDEAEA;
  height: 3rem;
  width: 3rem;
}
.wraps {
  flex-wrap: wrap;
}
.transf img,
.trynow {
  transition: transform 2s, 2s;
  padding: 0;
}
.hover:hover,
.trynow:hover {
  cursor: pointer;
  border-radius: 20px;
  box-shadow: rgba(190, 190, 240, 0.5) 0px 7px 29px 0px;
}
.cardWrapper {
  border: none;
}
.grytxt {
  color: var(--dk-grey-color);
}
.w-75 {
  width: 75%;
}
.w-90 {
  width: 90%;
}
.w-50 {
  width: 50%;
}
.w-95 {
  width: 95%;
}
.w-80 {
  width: 80%;
}
.aut {
  margin: 0 auto;
}
.ant-col {
  margin-bottom: 20px;
}
.ant-input {
  height: 55px;
}
@media screen and (max-width: 760px) {
  .d-sm-show {
    display: block;
  }
  .tcents {
    text-align: left !important;
  }
  .pl0 {
    padding-left: 0;
  }
  .mt1,
  .mt2 {
    margin-top: 1rem;
  }
  h1 {
    font-weight: 500;
    font-size: 25px;
  }
  .w-75,
  .w-90,
  .w-80 {
    width: 100%;
  }
  .bgs {
    width: 70%;
  }
  .h1s {
    font-size: 25px;
  }
  .pt4 {
    padding: 1rem 0;
  }
  .px {
    padding: 1rem 1.5rem;
  }
  .d-sm-none {
    display: none!important;
  }
  .fmp {
    font-size: 12px;
  }
  .w-50 {
    margin-left: -1px;
  }
  .mr1 {
    margin-right: 1rem;
  }
  .mxs {
    width: 95%;
    margin-left: -12px;
  }
  .circleNotif {
    height: 2.8rem;
    width: 3.6rem;
  }
}
@media screen and (min-width: 760px) and (max-width: 900px) {
  h1,
  h2,
  h3 {
    font-size: 27px;
  }
  .w-75,
  .w-80 {
    width: 90%;
  }
  .px {
    padding: 1rem 1.5rem;
  }
  .d-md-none {
    display: none!important;
  }
  .d-md-show {
    display: block;
  }
}
