// reusable
abbr{
  cursor: text!important;
  text-decoration: none!important;
}
.mnheight {
  min-height: 65vh;
}
.w-100 {
  width: 100%;
}
.mdle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoslogs.mdle{
  max-width: 200px;
}
.txtwhite {
  color: var(--pri-white);
}
.flex {
  display: flex;
}
body {
  font-family: "DM Sans", sans-serif;
}
.h50 {
  height: 50%;
}
.h80 {
  height: 80%;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 60%;
}
.h40 {
  height: 40px;
}
.maxHeight {
  max-height: 55vh;
}
.height-pay {
  height: 40vh;
}
.pad {
  padding: 1rem;
}
.aligncent {
  align-items: center;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 32px;
  left: 25px;
}
.ftsmall {
  font-size: 10px;
}
//
.logoslogs img {
  width: 50%;
}
.spacebtw {
  justify-content: space-between;
}
.capitalize {
  text-transform: capitalize;
}
.ant-layout-header,
.ant-layout-sider,
.ant-layout-sider-zero-width-trigger,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: var(--pri-white);
  color: black;
}

.links {
  color: var(--lt-grey-color) !important;
  font-weight: bolder;
  display: flex;
  justify-content: flex-start;
}

.links:focus {
  color: var(--primary-color) !important;
}

.ant-layout {
  background-color: var(--pri-white);
  border-left: 1px solid #f6f6f6;
}
.ant-layout-sider {
  padding-top: 1em;
}
.side-menu {
  overflow: auto;
  height: 90vh;
  left: 0;
}
.side-menu::-webkit-scrollbar {
  display: none;
}
.borddashb {
  border-bottom: 1px solid #f6f6f6;
}
.ant-tooltip {
  visibility: hidden;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--pri-white);
}
.ant-menu-item-selected > span a,
.ant-menu-item-active > span a {
  color: var(--primary-color) !important;
}
.iconss {
  padding-left: 20px;
}
.menus {
  margin-bottom: 2rem !important;
}

.logout {
  border: none;
  background-color: var(--pri-white);
  color: #e0333c;
  font-weight: bolder;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flags {
  font-size: 25px;
}
.hbtn {
  margin-right: 1rem;
  border: none;
}
.content-wrapper {
  padding: 1rem 1rem 1rem 3rem;
}

.bellnot {
  background-color: var(--belgry);
  padding: 5px 10px;
  color: #aaa5b0;
  // padding: 0;
}

.bd {
  font-weight: bold;
  font-size: 14px;
}
.header-wrapper {
  padding: 0 2rem;
}
.floatRight {
  float: right;
}
.cardH {
  height: 40em;
}
// wallet
.wbtn {
  margin-top: 1rem;
  background-color: var(--pri-white);
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 9px;
  cursor: pointer;
}
.cardan {
  max-width: 300px;
  width: 95%;
  border-radius: 10px;
  cursor: pointer;
  min-height: 150px;
}
.cardWalletHistory {
  box-shadow: none;
  padding: 0 !important;
  outline: none;
  border: none !important;
  background: transparent !important;
}

// analytics

.tamt {
  background-color: #f6fff2;
  border: 1px solid #d7ffc4;
}
.torder {
  background-color: #f2f9ff;
  border: 1px solid #c3e9ff;
}
.completedo {
  background-color: #fefff2;
  border: 1px solid #fff5c3;
}
.cancelledo {
  background-color: #fff2f2;
  border: 1px solid #ffc3c3;
}
// actions
.cds {
  background-color: #fbfbfb;
  border: 1px solid #f7f7f7;
}
.cds span {
  font-size: 13px;
}
.cds img {
  width: 3rem;
  margin-top: -17px;
}
// recent trans
.rcnt img {
  height: 2.5rem;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
}
.rcntflx {
  display: flex;
  align-items: center;
  justify-content: center;
}
.recentCard {
  background-color: #fbfbfb;
  border-radius: 19px;
  // height: 70vh;
}
.recentCard .gMap {
  margin-top: 10px;
  height: 40vh;
}
.holder {
  border-bottom: 3px solid #f2f2f2;
  padding: 8px 0;
}
.prg {
  background: var(--primary-color);
  box-shadow: 0px 18px 40px -12px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 90%;
}
// progress bar
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #cefeb0;
  height: 15px !important;
}

.mapf img {
  width: 100%;
  // object-fit: contain;
}
.sms {
  font-size: 13px;
  font-weight: bold;
}
.amts {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
  font-size: 20px;
  font-weight: bolder;
}
.amtbtn {
  color: white !important;
  width: 11rem;
  height: 2.5rem;
  border-radius: 3px;
}
.amtbtn:disabled {
  background-color: var(--disabled-color);
}
.amtbtn:enabled {
  background-color: var(--primary-color);
}
.paymode {
  cursor: pointer;
}
.radios .ant-radio {
  margin-right: -30px;
  z-index: 999;
  padding: 0;
  color: rgba(247, 240, 240, 0.85);
  position: absolute;
  left: 1em;
  top: 0.5em;
}

.pmode {
  border: none;
  width: 150px;
}

// order
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  display: none;
}
.searchs {
  // max-width: 400px;
  width: 21rem;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child),
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 10px;
  border: none;
  background-color: #f9f9f9;
  box-shadow: none;
}
.searchs .ant-input-group .ant-input {
  height: 35px;
  // width: 400px;
  background-color: transparent;
}
.filter {
  border: 1px solid #c6c2de;
  height: 40px;
  border-radius: 10px;
  width: 90px;
  font-size: 16px;
  margin-right: 10px;
}
.filter svg {
  font-size: 25px;
  margin-right: 5px;
}
.ant-modal-header {
  border-bottom: none;
}
.ant-modal {
  border-radius: 20px !important;
}
.ordP {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.orderMap .mapf img {
  max-width: 500px;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}
.createO input {
  max-width: 300px;
  width: 90%;
  height: 45px;
  border-radius: 5px;
}
.createO .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.createO .ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  max-width: 300px;
  width: 90%;
}
.radios .ant-radio {
  margin-right: 0;
}
.orderSummary {
  display: flex;
}
.cardSum {
  background-color: #fbfbfb;
  border: 1px solid #f4f4f4;
  padding: 10px;
  width: 400px;
}
.radios p {
  font-size: 13px;
}
.radios .ant-radio-inner {
  background-color: transparent;
  border: none;
  transition: all 0.3s;
}

.radios .ant-radio-wrapper {
  border-radius: 5px;
  border: 1px solid var(--bordcol);
}
.radios .ant-radio-wrapper-checked {
  border: 1px solid var(--primary-color);
}
.radios .ant-radio {
  visibility: hidden;
}
.selectdropdown {
  box-shadow: none;
  background-color: transparent;
}
.selectdropdown div {
  padding: 5px 0;
}

.ant-modal-content {
  padding: 10px 30px 30px 20px;
}
.order-table span {
  font-size: 12px;
}
// profile

.profileEditIcon {
  position: absolute;
  top: 40%;
  left: 50px;
}
.profileTxt {
  font-size: 16px;
  display: block;
}
.profileForm input {
  height: 45%;
  width: 50%;
}
.ant-upload.ant-upload-select-picture-card {
  border: none !important;
  background-color: transparent !important;
}
.avatar-cont img {
  border-radius: 50% !important;
  border: 1px solid #d9d9d9;
}
// notification
.notificationList:hover {
  background-color: #fafafa !important;
}


@media screen and (max-width: 300px) {
  .walsd img,
  .wallet img {
    width: 15rem !important;
  }
  .walsd span {
    font-size: 15px;
  }
  .wbtn {
    margin: 5px !important;
  }
}
@media screen and (max-width: 400px) {
  .walsd img,
  .wallet img {
    padding-right: 20px;
  }
}
@media screen and (max-width: 700px) {
  .step-wrapper {
    margin-left: -4rem;
  }
}
@media screen and (max-width: 760px) {
  .ant-layout.ant-layout-has-sider {
    overflow: hidden;
  }
  .orderSummary {
    display: block;
  }
  .ant-layout-sider-zero-width-trigger {
    top: 20px;
    // left: 100%!important;
  }
  .hbtn {
    margin-right: 10px;
    width: 100px;
  }
  .borddashb {
    padding-left: 50px;
  }
  .absolute {
    top: 20px;
    left: 20px;
  }
  .walsd img,
  .wallet img {
    width: 18rem;
  }

  .sms {
    font-size: 10px;
  }
  .smb {
    font-size: 12px;
  }
  .rcntflx {
    margin-right: 10px;
  }
  .mnheight {
    height: unset;
  }
  .content-wrapper {
    overflow: hidden;
    padding: 4rem 2rem;
    margin-top: 1rem;
  }
  .filter,
  .filter svg {
    font-size: 12px;
  }

  .cardSum {
    max-width: 300px;
  }
  .ant-table {
    overflow-x: scroll;
  }
  .order2 {
    order: 2 !important;
  }
  .pmode {
    width: 80px;
    height: 80px;
    text-align: center;
  }
  .pmode p {
    line-height: 20px !important;
  }
  .pmode .ant-card-body {
    padding: 0 !important;
  }
  .orderM .ant-modal-body,
  .orderM .ant-modal-content {
    padding: 0 !important;
  }
  .orderM h3 {
    padding: 3rem 1rem 0 1rem;
  }
  .recentCard .gMap {
    height: unset;
  }
  .cardH {
    height: 100%;
  }
}
@media screen and (min-width: 760px) and (max-width: 1000px) {
  .content-wrapper {
    padding-top: 4rem;
    margin-top: 1rem;
  }
  .t-mdle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-layout-sider-zero-width-trigger {
    top: 10px;
    // left: 100%!important;
  }
  .mnheight {
    min-height: unset !important;
  }
  .walsd img {
    width: 20rem;
  }
  .absolute {
    top: 20px;
    left: 20px;
  }
  .cardan h3 {
    font-size: 15px;
  }
  .cardan {
    min-height: 180px;
  }
  // .cardSum{
  //   max-width: 200px!important;
  //   width: 90%;
  // }
  .ant-table {
    overflow-x: scroll;
  }
  .orderSummary h3 {
    font-size: 20px;
  }
  .cardSum {
    width: 300px;
  }
  .maxHeight {
    max-height: 28rem;
  }
  .height-pay {
    height: 20rem;
  }
}

.ant-layout-content{
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}


@primary-color: undefined;@success-color: undefined;@disabled-color: undefined;@secondary-color: undefined;