@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkW-EL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODL7E.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTg.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTg.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwARZthTg.ttf) format('truetype');
}
.lforms {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}
.flex-column {
  flex-direction: column;
  justify-content: space-around;
}
.auth-submit-btn {
  width: 100%;
}
.auth-blur-bg {
  filter: blur(50px);
}
.mdle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mds {
  display: flex;
  align-items: center;
}
.otp-box-wrap {
  gap: 18px;
}
.h50 {
  height: 50%;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 60%;
}
.lgbg {
  background: url("../common/asset/loginbg.jpg");
  background-position: center;
  background-size: cover;
  font-family: "Cabin", sans-serif;
  margin: 0;
  background-repeat: no-repeat;
}
.fmcol {
  font-family: "DM Sans", sans-serif;
}
.auth-form {
  height: 100vh;
  overflow-y: scroll;
}
.logoslog {
  width: 30% !important;
}
.txfm {
  color: var(--pri-white);
}
.txfm h3 {
  font-size: 30px;
  font-weight: 600;
  color: var(--pri-white);
}
.lgfms {
  margin: 1rem auto auto auto !important;
}
.txfm p {
  font-weight: 600;
  line-height: 28px;
  font-size: 17px;
}
.lgfms .ant-input,
.lgfms .ant-input-affix-wrapper {
  border: none !important;
  background-color: transparent;
  outline: none !important;
  border-radius: unset;
  box-shadow: none !important;
  padding: 0;
  font-size: 18px !important;
}
.lgfms .inp {
  border-bottom: 2px solid var(--bordcol) !important;
  box-shadow: none !important;
}
.fmpr {
  font-size: 17px;
}
.small p {
  font-size: 10px;
  line-height: 10px;
}
/* Change the white to any color */
.lgfms input:-webkit-autofill,
.lgfms input:-webkit-autofill:hover,
.lgfms input:-webkit-autofill:focus,
.lgfms input:-webkit-autofill:active,
.lgfms #basic_password:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background: white !important;
}
.regbtn {
  height: 50px;
  border-radius: 5px;
  color: var(--pri-white);
  font-size: 16px;
  font-weight: 500;
}
.regs:enabled {
  background-color: #035d9f;
}
.exc {
  font-size: 15px;
  padding: 5px;
  color: var(--pri-white);
  border-radius: 50%;
  background-color: var(--noticol);
}
.notify div {
  font-size: 11px !important;
}
.inputn input {
  max-width: 40px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #7677ae;
}
.dangerText {
  color: red;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}
@media screen and (max-width: 760px) {
  .logoslog {
    width: 40% !important;
  }
  .fmcol .h90 {
    height: 80%;
  }
  .lforms {
    width: 100%;
  }
  .lforms .fmcol {
    padding: 1.5rem 1rem !important;
  }
}
@media screen and (min-width: 760px) and (max-width: 1030px) {
  .t-mdle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lforms {
    min-height: 105vh;
  }
  .h50 {
    height: 60%;
  }
  .lgbg {
    padding: 1rem;
  }
  .links img {
    margin: 1rem 0;
  }
}
.containers {
  padding: 3rem 4rem 3rem 5.5rem;
}
.links {
  margin-right: 28px;
}
.transf img,
.trynow {
  transition: transform 2s, 2s;
  padding: 0;
}
.mobile-talks img {
  max-width: 50%;
}
.mobile-talks a {
  margin-right: 28px;
}
